import React, {Component} from 'react';
import {withRouter} from "react-router";

class Footer extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <footer>
                <div className="container padding20" style={{display: "flex", color:"#fff",    alignItems: "center"}}>
                    <a className="col-xs-2 col-sm-2 col-md-2 col-lg-2" href="https://www.beniculturali.it" target="_blank">
                        <img className="img-responsive partner" src="images/loghi/MIC_logo_footer.png" alt="Ministero per i beni culturali" width="100"/>
                    </a>
                    <a className="col-xs-2 col-sm-2 col-md-2 col-lg-2" href="http://www.degasperitn.it" target="_blank">
                        <img className="img-responsive partner" src="images/loghi/degasperitn-logo.png" alt="Fondazione trentina Alcide De Gasperi" width="100" style={{width:"62%"}}/>
                    </a>
                    <a className="col-xs-2 col-sm-2 col-md-2 col-lg-2" href="http://fbk.eu" target="_blank">
                        <img className="img-responsive partner" src="images/loghi/FBK_BASE.png" alt="Fondazione Bruna Kessler" style={{width:"45%"}}/>
                    </a>
                    <a className="col-xs-2 col-sm-2 col-md-2 col-lg-2" href="https://www.sturzo.it/" target="_blank">
                        <img className="img-responsive partner" src="images/loghi/istututoLuigiSturzoContrast.png" alt="Istituto Luigi Sturzo" width="100"/>
                    </a>
                    <a className="col-xs-2 col-sm-2 col-md-2 col-lg-2" href="http://dh.fbk.eu" target="_blank">
                        <img className="img-responsive partner" src="images/loghi/logo_dh.png" alt="Digital Humanities" width="100"/>
                    </a>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 footer">
                        <p style={{margin:"0px",flexDirection: "column",display:"flex"}}>
                            <span>Edizione digitale dell'Epistolario di Alcide De Gasperi</span>
                            <span>ISBN   9788894438000</span>
                            <span>ISBN-A   10.978.88944380/00</span>
                            <span style={{cursor:"pointer"}}><a style={{color:"#ef6334"}} target={"_blank"} onClick={()=>{this.props.history.push("trasparenza")}}>Amministrazione trasparente</a></span>
                        </p>
                    </div>

                </div>
                <br/>
            </footer>
        );
    }
}

export default withRouter(Footer);
