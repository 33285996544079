import React from 'react';
import Loadable from 'react-loadable'

// import CodeEditors from './views/Editors/CodeEditors'
import DefaultLayout from './container/DefaultLayout';

function Loading() {
    return <div>Loading...</div>;
}

const Home = Loadable({
    loader: () => import('./views/home'),
    loading: Loading,
});

const Contacts = Loadable({
    loader: () => import('./views/contacts'),
    loading: Loading,
});

const EdNazionale = Loadable({
    loader: () => import('./views/ednazionale'),
    loading: Loading,
});

const Commisione = Loadable({
    loader: () => import('./views/commisione'),
    loading: Loading,
});

const Team = Loadable({
    loader: () => import('./views/team'),
    loading: Loading,
});

const Network = Loadable({
    loader: () => import('./views/network'),
    loading: Loading,
});


const Biografia = Loadable({
    loader: () => import('./views/biografia'),
    loading: Loading,
});

const Biografie = Loadable({
    loader: () => import('./views/biografie'),
    loading: Loading,
});

const Luoghi = Loadable({
    loader: () => import('./views/luoghi'),
    loading: Loading,
});

const Cronologia = Loadable({
    loader: () => import('./views/cronologia'),
    loading: Loading,
});


const BibliografiaOld = Loadable({
    loader: () => import('./views/bibliografia'),
    loading: Loading,
});

const Bibliografia = Loadable({
    loader: () => import('./views/bibliografiaNew'),
    loading: Loading,
});

const Archivio = Loadable({
    loader: () => import('./views/archivio'),
    loading: Loading,
});

const Criteri = Loadable({
    loader: () => import('./views/criteri'),
    loading: Loading,
});


const Risorse = Loadable({
    loader: () => import('./views/risorse'),
    loading: Loading,
});


const NoteLegali = Loadable({
    loader: () => import('./views/noteLegali'),
    loading: Loading,
});


const Trasparenza = Loadable({
    loader: () => import('./views/trasparenza'),
    loading: Loading,
});

const Segnalazioni = Loadable({
    loader: () => import('./views/segnalazioni'),
    loading: Loading,
});

const Collaborazioni = Loadable({
    loader: () => import('./views/collaborazioni'),
    loading: Loading,
});

const PersorsiTematici = Loadable({
    loader: () => import('./views/persorsi_tematici'),
    loading: Loading,
});

const FasiBiografiche = Loadable({
    loader: () => import('./views/fasi_biografiche'),
    loading: Loading,
});

const RicercaTematica = Loadable({
    loader: () => import('./views/ricerca_tematica'),
    loading: Loading,
});

const ArchivioDigitale = Loadable({
    loader: () => import('./views/archivio_digitale'),
    loading: Loading,
});



const Piattaforma = Loadable({
    loader: () => import('./container/PlatformContainer'),
    loading: Loading,
});


const routes = [
    { path: '/', name: 'Home', component: Home, exact: true },
    { path: '/contatti', name: 'Contatti', component: Contacts},
    { path: '/ednazionale', name: 'Edizione Nazionale', component: EdNazionale },
    { path: '/commissione', name: 'Commisione', component: Commisione },
    { path: '/team', name: 'Team', component: Team },
    { path: '/network', name: 'Network', component: Network },
    { path: '/biografia', name: 'Biografia', component: Biografia },
    { path: '/biografie', name: 'Biografie', component: Biografie },
    { path: '/luoghi', name: 'Luoghi', component: Luoghi },
    { path: '/cronologia', name: 'Cronologia', component: Cronologia },
    { path: '/bibliografia', name: 'Bibliografia', component: Bibliografia },
    // { path: '/bibliografiaNew', name: 'Bibliografia', component: BibliografiaNew },
    { path: '/archivio', name: 'Archivio', component: Archivio },
    { path: '/criteri', name: 'Criteri Editoriali', component: Criteri },
    { path: '/risorse', name: 'Risorse', component: Risorse },
    { path: '/notelegali', name: 'NoteLegali', component: NoteLegali },
    { path: '/trasparenza', name: 'Trasparenza', component: Trasparenza },
    // { path: '/segnalazioni', name: 'Segnalazioni lettere', component: Segnalazioni },
    { path: '/collaborazioni', name: 'Collaborazioni', component: Collaborazioni },
    { path: '/archivio_digitale/lettere', name: 'Archivio Digitale', component: Piattaforma },
    { path: '/archivio_digitale/cerca', name: 'Archivio Digitale', component: Piattaforma },
    { path: '/archivio_digitale/lettera', name: 'Archivio Digitale', component: Piattaforma },
    { path: '/archivio_digitale/letteraOld', name: 'Archivio Digitale', component: Piattaforma },
    { path: '/archivio_digitale', name: 'Archivio Digitale', component:  ArchivioDigitale},
    { path: '/fasi_biografiche', name: 'Fasi Biografiche', component: FasiBiografiche },
    { path: '/ricerca_tematica', name: 'Ricerca Tematica', component: RicercaTematica }

    // { path: '/archivio_digitale/lettere', name: 'Archivio Digitale', component: Piattaforma }
];

export default routes;
