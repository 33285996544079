import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from "history";
import DefaultLayout from "./container/DefaultLayout"
// import "./styles/fonts.css"
import "./styles/bootstrap.css"
import "./styles/override.css"
// import "./styles/style_custom.css"

const hist = createBrowserHistory();

class App extends Component {



    render() {
        return (
            <HashRouter history={hist}>
                <Switch>
                    <Route path="/" name="Home" component={DefaultLayout}/>
                </Switch>
            </HashRouter>
        );
    }
}

export default App;
