import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {IntlProvider} from "react-intl";
import {addLocaleData} from "react-intl";

import en from 'react-intl/locale-data/en';
import it from 'react-intl/locale-data/it';
import messages_it from "./translations/it.json";
import messages_en from "./translations/en.json";

addLocaleData([...en, ...it]);

const messages = {
    'it': messages_it,
    'en': messages_en
};

const language = navigator.language.split(/[-_]/)[0];

// window.apiEpistolarioSiteUrlPrefix = "https://localhost:8443/"
// window.apiEpistolarioSiteUrlPrefix = "http://localhost:8080/"
// window.apiEpistolarioSiteUrlPrefix = "https://dh-server.fbk.eu/"
// window.apiEpistolarioSiteUrlPrefix = "https://epistolariodegasperi.it/"
window.apiEpistolarioSiteUrlPrefix = ""


ReactDOM.render(<IntlProvider locale={language} messages={messages[language]}>
    <App/>
</IntlProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
