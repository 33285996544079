import React, {Component} from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import {Container} from "reactstrap";
import {  Route, Switch, withRouter} from 'react-router-dom';

import routes from '../routes';


class DefaultLayout extends Component {

    componentDidMount() {



    }

    render() {

        return (
            <>
                <Header/>
                <Container fluid>
                    <Switch>
                        {routes.map((route, idx) => {

                                return (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                        <route.component {...props} />
                                    )} />)

                            },
                        )}
                        {/*<Redirect from="/" to="/home" />*/}
                    </Switch>
                </Container>
                <Footer/>

            </>
        );
    }
}

export default withRouter(DefaultLayout);