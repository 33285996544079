import React, {Component} from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap"

import "font-awesome/css/font-awesome.css"

import { FormattedMessage } from 'react-intl';

import $ from "jquery";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core/styles";


// import "./Header.css"

// const history = createBrowserHistory();

class Header extends Component {

    constructor(props) {
        super(props);

    }



    componentDidMount() {
        $(".dropdown-toggle").prop('onclick', null).off('click');
        $(".dropdown-toggle").click(function (e) {
            for (let item of document.getElementsByClassName("nav-link active")) {
                item.classList.remove("active")
            }
            for (let item of document.getElementsByClassName("dropdown-menu show")) {
                item.classList.remove("show")
            }
            for (let item of document.getElementsByClassName("dropdown nav-item")) {
                item.classList.remove("show")
            }
            let elem = e.target
            // console.log(elem);
            if (elem.classList.contains("dropdown-toggle")) {
                elem.setAttribute("aria-expanded", 'true')
                elem.parentElement.classList.add("show")
            }else{
                elem.parentElement.setAttribute("aria-expanded", 'true')
                elem.parentElement.parentElement.classList.add("show")
            }
            if (elem.parentElement.classList.contains("dropdown")){
                elem.parentElement.childNodes[1].classList.add("show")
            }else{
                elem.parentElement.parentElement.childNodes[1].classList.add("show")
            }

            // elem.parentElement.childNodes[1].classList.add("show")
            e.preventDefault();
            e.stopPropagation()
        })

        // console.log(window.location.hash)

        let activeItem = document.querySelectorAll("a[href='"+window.location.hash+"']")[0];


        if (activeItem !== undefined) {
            activeItem.setAttribute("aria-expanded", 'true');
            activeItem.parentElement.classList.add("show")
            activeItem.classList.add("active")
            activeItem.parentElement.parentElement.classList.add("show")
            if (activeItem.parentElement.childNodes[1].classList.contains("dropdown-item")) {
                activeItem.parentElement.childNodes[1].classList.add("show")
            }
        }
    }



    render() {
        return (
            <div>

                <Navbar expand="lg">
                    <div className="social_top" style={{marginTop:"5px"}}>
                        <a href="https://twitter.com/EpistolarioDG" target="_blank">
                            <span className="fa fa-twitter" aria-hidden="true" style={{color: "#ffffff",fontSize: "20px"}}></span>
                        </a>
                        <a href="mailto:redazione@epistolariodegasperi.it">
                            <span className="fa fa-pencil" aria-hidden="true" style={{color: "#ffffff",fontSize: "18px"}}></span>
                        </a>

                    </div>
                    <div className="container">

                        <Navbar.Brand href="/">
                            <img src="images/logo.png" className="img-responsive" alt="Logo Epistolario Degasperi" style={{width: "150px"}}/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto" activeKey={window.location.hash}>
                                <NavDropdown title={<FormattedMessage id="menu.chisiamo" />} >
                                    <NavDropdown.Item href="#/ednazionale">{<FormattedMessage id="submenu.ednazionale" />}</NavDropdown.Item>
                                    <NavDropdown.Item href="#/commissione">{<FormattedMessage id="submenu.commissione" />}</NavDropdown.Item>
                                    <NavDropdown.Item href="#/team">{<FormattedMessage id="submenu.team" />}</NavDropdown.Item>
                                    <NavDropdown.Item href="#/network">{<FormattedMessage id="submenu.network" />}</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Alcide De Gasperi" >
                                    <NavDropdown.Item href="#/biografia">{<FormattedMessage id="submenu.biografia" />}</NavDropdown.Item>
                                    <NavDropdown.Item href="#/cronologia">{<FormattedMessage id="submenu.cronologia" />}</NavDropdown.Item>
                                    {/*<NavDropdown.Item href="#/bibliografia">{<FormattedMessage id="submenu.bibliografia" />}</NavDropdown.Item>*/}
                                </NavDropdown>
                                <NavDropdown title={<FormattedMessage id="menu.epistolario" />} >
                                    <NavDropdown.Item href="#/archivio_digitale">{<FormattedMessage id="submenu.archivio" />}</NavDropdown.Item>
                                    <NavDropdown.Item href="#/criteri">{<FormattedMessage id="submenu.criteri" />}</NavDropdown.Item>
                                    <NavDropdown.Item href="#/risorse">{<FormattedMessage id="submenu.risorse" />}</NavDropdown.Item>
                                    <NavDropdown.Item href="#/noteLegali">{<FormattedMessage id="submenu.noteLegali" />}</NavDropdown.Item>
                                </NavDropdown>
                                {/*<NavDropdown title={<FormattedMessage id="submenu.bibliografia" />} />*/}
                                <Nav.Link href="#/bibliografia">{<FormattedMessage id="submenu.bibliografia" />}</Nav.Link>
                                {/*<NavDropdown title={<FormattedMessage id="menu.partecipa" />}  >*/}
                                {/*    /!*<NavDropdown.Item href="#/segnalazioni">{<FormattedMessage id="submenu.segnalazione" />}</NavDropdown.Item>*!/*/}
                                {/*    <NavDropdown.Item href="#/collaborazioni">{<FormattedMessage id="submenu.collaborazioni" />}</NavDropdown.Item>*/}
                                {/*</NavDropdown>*/}
                                <Nav.Link href="#/contatti">{<FormattedMessage id="menu.contatti" />}</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
                <div className="subnav" id="navbar_epistolario_empty" style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#333",
                    color: "#fff",
                    height: "50px", overflow: "hidden"
                }}>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);